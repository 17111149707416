import { render, staticRenderFns } from "./PortalModuleCard.vue?vue&type=template&id=73bd6f14&scoped=true&"
import script from "./PortalModuleCard.vue?vue&type=script&lang=js&"
export * from "./PortalModuleCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73bd6f14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\DevOpsAgents\\Agent-02\\_work\\130\\s\\KM.Dataarkiv.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73bd6f14')) {
      api.createRecord('73bd6f14', component.options)
    } else {
      api.reload('73bd6f14', component.options)
    }
    module.hot.accept("./PortalModuleCard.vue?vue&type=template&id=73bd6f14&scoped=true&", function () {
      api.rerender('73bd6f14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Portal/Card/PortalModuleCard.vue"
export default component.exports